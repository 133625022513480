import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=3b676fd2&scoped=true&"
import script from "./ProjectCard.vue?vue&type=script&lang=js&"
export * from "./ProjectCard.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=3b676fd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b676fd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VIcon})
