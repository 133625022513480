<template>
  <v-container class="home mb-16">
    <projects-index />
  </v-container>
</template>
<script>
import ProjectsIndex from "@/views/Projects/Index";
export default {
  name: "Home",
  components: { ProjectsIndex },

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss">
.home {
  height: 100%;
  padding: 0;
}
</style>
