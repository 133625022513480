<template>
  <v-card flat class="home-card" :loading="isLoading">
    <v-row class="pb-5" style="width: 100%">
      <v-col cols="3" v-for="draft in drafts" :key="draft.draft">
        <project-card
          :project="draft"
          :is-loading="isLoading"
          :remove-project="removeDraft"
          draft
        />
      </v-col>
      <v-col cols="12" class="pt-8">
        <v-divider v-if="projects.length > 0 && drafts.length > 0" />
      </v-col>
    </v-row>

    <v-row style="width: 100%">
      <v-col
        class="no-projects"
        v-if="projects.length < 1 && !isLoading && drafts.length < 1"
      >
        <h1 class="empty">
          {{ $t("PROJECTS.NO_PROJECTS") }}
        </h1>
      </v-col>
      <v-col
        v-else
        cols="3"
        v-for="project in projects"
        :key="project.projectId"
      >
        <project-card
          :project="project"
          :is-loading="isLoading"
          :remove-project="removeProject"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ProjectCard from "@/views/Projects/components/ProjectCard";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProjectsIndex",
  components: { ProjectCard },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      projects: "api/project/getProjects",
      drafts: "api/project/getDrafts"
    })
  },
  mounted() {
    if (this.projects.length < 1 || this.drafts.length < 1) {
      this.isLoading = true;
      this.getProjects().finally(() => {
        this.isLoading = false;
      });
    }
  },
  methods: {
    ...mapActions({
      getProjects: "api/project/getProjects",
      deleteProject: "api/project/deleteProject",
      deleteDraft: "api/draft/deleteDraft"
    }),
    removeProject(id, method = this.deleteProject) {
      const action = () => {
        this.isLoading = true;
        method(id)
          .catch(error => {
            console.log(error);
            this.$notifier.notify({
              message: "Project can not be deleted!",
              color: "error"
            });
            this.isLoading = false;
          })
          .then(() => {
            this.getProjects().finally(() => {
              this.isLoading = false;
            });
          });
      };
      this.$modals.confirmation({
        action
      });
    },
    removeDraft(id) {
      this.removeProject(id, this.deleteDraft);
    }
  }
};
</script>

<style lang="scss" scoped>
.no-projects {
  height: 100%;
}
.home-card {
  background: none;
  display: flex;
  flex-wrap: wrap;
}
.empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
</style>
