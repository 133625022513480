<template>
  <v-card
    dark
    :color="cardColor"
    class="card"
    tile
    elevation="8"
    :disabled="isLoading"
    @click="goToProject"
  >
    <header class="card-header">
      <v-card-text class="caption">
        <div v-if="knowledgeBase" class="opacity-7">
          Author: {{ project._user.fullname }}
        </div>
        <span class="opacity-3">
          created: {{ $moment(project.createdAt).format("hh:ss A, MMM D, Y") }}
        </span>
      </v-card-text>
      <v-card-actions v-if="!knowledgeBase">
        <v-btn
          icon
          color="white"
          @click.stop="
            removeProject(project.projectId ? project.projectId : project.draft)
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </header>
    <v-card-title class="pt-6 pb-3 card-title">
      {{ project.name ? project.name : $t("COMMON.NAME_NOT_SPECIFIED") }}
      ({{ project._market.name }})
    </v-card-title>
    <v-card-text v-if="!knowledgeBase" class="text-center card-status">
      {{ project.status | transformStatus }}
    </v-card-text>
    <v-card-text
      v-if="project.startDate && project.endDate"
      class="caption date-range opacity-7"
    >
      {{
        `${$moment(project.startDate).format("MMM D, Y")} -
         ${$moment(project.endDate).format("MMM D, Y")}`
      }}
    </v-card-text>
    <v-card-text v-else class="caption date-range opacity-7">
      {{ $t("PROJECT.DATE_NOT_SPECIFIED") }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
      required: true
    },
    draft: {
      type: Boolean,
      default: false
    },
    knowledgeBase: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    removeProject: {
      type: Function,
      required: false
    }
  },
  filters: {
    transformStatus: value => {
      if (!value) return "DRAFT";
      value = value.toString();
      return value.toUpperCase().replaceAll("_", " ");
    }
  },
  beforeDestroy() {
    // this.setProject(null);
  },
  computed: {
    cardColor() {
      return this.draft ? "secondary" : "primary";
    }
  },
  methods: {
    ...mapMutations({
      setProject: "api/project/setProject"
    }),
    ...mapActions({
      deleteProject: "api/project/deleteProject",
      getProjects: "api/project/getProjects"
    }),
    goToProject() {
      let projectId = this.project.projectId;

      if (this.draft) {
        projectId = this.project.draft;
      }

      if (this.knowledgeBase) {
        projectId = this.project._id;

        this.$router.push({
          name: "KnowledgeBaseProject",
          params: {
            projectId,
            draft: null
          }
        });
      } else {
        this.$router.push({
          name: "Project",
          params: {
            projectId,
            draft: this.draft ? "draft" : null
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  display: flex;
}
.card-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 96px;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  word-break: keep-all;
}
.card-status {
}
.date-range {
  text-align: center;
}
</style>
